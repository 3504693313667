export const typeProduct = [
  {value: 'video', label: 'Видеоролик'},
  {value: 'audio', label: 'Аудиоролик'},
  {value: 'photo', label: 'Фотосессия'},
  {value: 'news', label: 'Новостной сюжет'},
  {value: 'film', label: 'Фильм'},
  {value: 'other', label: 'Другое'},

]




export const typeWork = [
  {value: 'fromScratch', label: 'Разработка с нуля'},
  {value: 'adjustment', label: 'Коректировка'},
]